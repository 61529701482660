import styled from '@emotion/styled';
import * as Accordion from '@radix-ui/react-accordion';
import React from 'react';
import { HiChevronDown } from 'react-icons/hi';

interface Props {
  question: string;
  answer: string;
}

const FAQcontnts = (props: Props) => {
  return (
    <Wrapper>
      <Accordion.Root type="multiple" className="QAbox wmax">
        <Accordion.Item value="item-1" className="wmax item">
          <Accordion.Header className="wmax">
            <Accordion.Trigger className="wmax trigger">
              <div className="question">
                <p>{props.question}</p>
                <HiChevronDown className="chevron-down" />
              </div>
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content className="answer">
            <div className="QAspace">
              {props.answer.split('~').map((line, index) => (
                <p key={index}>{line}</p>
              ))}
            </div>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion.Root>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .QAbox {
  }

  .question {
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .QAspace {
    //height: 4.167vw;
    min-height: 60px;
    max-height: 200px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
  }

  .answer {
    color: #828282;
    width: 100%;
    background-color: #f9f9f9;
    line-height: 1.5;
    font-size: 14px;
    white-space: pre-wrap;
  }

  .wmax {
    width: 100%;
  }

  .question {
    line-height: 1.5;
  }

  .trigger {
    height: 4.167vw;
    min-height: 60px;
    max-height: 80px;
    &:hover {
      background-color: #f9f9f9;
    }
  }

  .trigger[data-state='open'] {
    .chevron-down {
      transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
      transform: rotate(180deg);
    }
  }

  .trigger[data-state='closed'] {
    .chevron-down {
      transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
      transform: rotate(0deg);
    }
  }

  .answer[data-state='open'] {
    animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
    border-top: 1px solid #dddddd;
  }

  .answer[data-state='closed'] {
    animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  @keyframes slideDown {
    from {
      height: 0;
      opacity: 0;
    }
    to {
      height: var(--radix-accordion-content-height);
      opacity: 1;
    }
  }

  @keyframes slideUp {
    from {
      height: var(--radix-accordion-content-height);
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    to {
      height: 0;
      opacity: 0;
    }
  }
`;

export default FAQcontnts;
